<template>
    <div class="container_detail" style="background: #1c1c1c;">
        <div class="header_Css">
            <div @click="toBack"><img src="../assets/back.png" alt=""></div>
            <h2>Recarga Diaria De Recompensas Acumuladas</h2>
            <span @click="toUrl('/home/records?num=14')">Histórico</span>
        </div>
        <!-- <div style="height: 10px;"></div> -->
        <div class="JYJJ_css" style="margin: 10px;">
            <div class="JYJJ_css_1">
                <span>Perda de ontem <span v-if="rechargeDetail.lastWeekLost>0" style="color: green;">{{ rechargeDetail.lastWeekLost }}</span><span v-else style="color: green;">0</span></span>
                &nbsp;&nbsp; <span>Subsídio de hoje <span style="color: #feaf75;">{{ rechargeDetail.reward }}</span>
                </span>
            </div>
            <table>
                <tr style="border: 1px solid #616161;background: #303030;">
                    <td style="width: 50%">Depósito Total</td>
                    <td style="width: 50%">Valor Da Recompensa</td>
                </tr>
                <tr :data="index" v-for="(item,index) in boxList.itemList">
                    <td style="width: 50%">≥ {{item.cumAmount |formatNumber}}</td>
                    <td style="width: 50%">{{item.rewardAmount |formatNumber}}</td>
                </tr>

                <!-- <tr style="border: 1px solid #616161;background: #303030;">

                    <td>≥ 1.000</td>
                    <td>2</td>
                </tr> -->
            </table>

            <div class="JYJJ_css_2">
                <div class="JYJJ_css_2_1">
                    <span style="font-weight: 700;padding-left: 5px;padding-top: 5px;">Instruções Do Evento:</span>
                    <div style="padding: 5px;text-align: justify">1.Durante o evento, a recarga acumulada de Diário receberá diferentes níveis de recompensas.
                        Quanto mais recargas, maiores serão as recompensas. A recompensa mais alta é
                        17777；<br>2.Depósito sem limite. Recompensas previstas para serem atualizadas em 10 minutos. Por
                        favor, aguarde a distribuição da recompensa;<br>3.As recompensas recebidas só podem ser
                        resgatadas após No dia seguinte00:00:00. O resgate só pode ser feito manualmente no
                        APP/iOS、APP/Android、PC/Windows.<br>4.As recompensas recebidas expirarão 1 dias após o término de
                        cada ciclo. As recompensas expiradas serão canceladas automaticamente;<br>5.O bônus (excluindo o
                        principal) deste evento requer 1 apostas válidas para ser sacado, e as apostas não são limitadas
                        a plataforma do jogo;<br>6.Este evento é limitado a operações normais realizadas pelo titular da
                        conta. É proibido alugar, usar plug-ins externos, robôs, apostar em contas diferentes, brushing
                        mútuo, arbitragem, interface, protocolo, exploração de vulnerabilidades, controle de grupo ou
                        outros meios técnicos para participar. Caso contrário, as recompensas serão canceladas ou
                        deduzidas, a conta será congelada ou até mesmo adicionada à lista negra;<br>7.Para evitar
                        diferenças na compreensão do texto, a plataforma reserva-se o direito de interpretação final
                        deste evento.<br></div>
                </div>
            </div>

            <div class="JYJJ_css_3">
                <div class="JYJJ_css_3_1" @click="toBack">
                    Retornar
                </div>
                <div v-if="rechargeDetail.status==0"  class="JYJJ_css_3_2">
                    Coletar Tudo
                </div>
                <div v-if="rechargeDetail.status==1"  class="JYJJ_css_3_1" @click="receiveRechargeReward">
                    Coletar Tudo
                </div>
            </div>

        </div>



<!--        <div class="qqq" style="margin-bottom: 80px;">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动01.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动02.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动03.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动04.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动05.png" alt="">-->
<!--            <img class="bom_img" src="@/assets/images/home/活动06.png" alt="">-->
<!--        </div>-->

    </div>
</template>

<script>
import { activityDetail , boxReward,getRechargeDetail,receiveRechargeReward} from '@/api/index'


export default {
    data() {
        return {

            boxList: {
            },
            totalLine: 0,
            activityId:this.$route.query.id,
            rechargeDetail:{}
        }
    },
    mounted() {
        this.getActivityDetail()
        this.getRechargeDetail()
    },
    methods: {
        toUrl(url){this.$router.push(url)},
        getActivityDetail() {
            activityDetail(
                this.$route.query.id
            ).then(res => {
                this.boxList = res.data
                if (this.boxList) {
                    let plus = this.boxList.itemList.length % 4;

                    this.totalLine = parseInt(this.boxList.itemList.length / 4);
                    if (plus > 0) {
                        this.totalLine = this.totalLine + 1;
                    }
                    console.log(this.totalLine)
                }
            })
        },
        toBack() {
            this.$router.push('/home/event')
        },
        getRechargeDetail(){
            getRechargeDetail(
                this.$route.query.id
            ).then(res => {
                // this.boxList = res.data
                this.rechargeDetail = res.data;
                // console.log(res)
            })
        },
        receiveRechargeReward(){
            receiveRechargeReward(this.activityId).then(res => {
                // this.boxList = res.data
                if (res.code==200){
                    this.$toast.success('Sucesso na obtenção');
                    this.getRechargeDetail()
                }else {
                    this.$toast.fail(res.msg);
                }
                // console.log(res)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header_Css {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;
    min-height: 40px;

    img {
        cursor: pointer;
    }

    h2 {
        color: #fff;
        font-size: 15px;
        width: 260px;
        text-align: center;
    }

    span {
        color: #feaf75;
        cursor: pointer;
    }
}

.JYJJ_css {
    .JYJJ_css_1 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 10px 0;
    }

    table {
        text-align: center;
        width: 100%;

        td {
            height: 48px;
        }
    }

    .JYJJ_css_2 {
        margin: 10px 0;

        .JYJJ_css_2_1 {
            background: #303030;
        }
    }
    .JYJJ_css_3{
        height: 67px;
        width: 100%;
        background: #303030;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        .JYJJ_css_3_1{
            cursor: pointer;
            border-radius: 10px;
            color: #cb8d71;
            width: 180px;
            border: 1px solid #cb8d71;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
        .JYJJ_css_3_2{
            cursor: pointer;
            border-radius: 10px;
            color: #ccc;
            width: 180px;
            border: 1px solid #999;
            background: #999;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
        }
    }
    .JYJJ_css_item4 {
        background: #303030;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        height: 40px;
        border-radius: 5px;
    }
}

.qqq {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    .bom_img {
        width: 104px;
        height: 50px;
        margin: 0 5px
    }
}
@media (min-width:751px) {
    .JYJJ_css_3{
        height: 67px;
        width: 435px !important;
        background: #303030;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 50% !important;
        transform: translateX(-50%);
    }
}
</style>
